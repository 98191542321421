
// Svg
import DotsSvg from '@/assets/dots.svg';
import TimesSvg from '@/assets/times.svg';
import NoticeSvg from '@/assets/notice.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VColumn from './VColumn.vue';
import VFileList from './VFileList.vue';
import VTaskCard from './VTaskCard.vue';
import VUsersList from './VUsersList.vue';
import VChatInput from './VChatInput.vue';
import VFeedStory from './VFeedStory.vue';
import VDatePicker from './VDatePicker.vue';
import VInlineLink from './VInlineLink.vue';
import VApprovementList from './VApprovementList.vue';
import VUserSelector from './VUserSelector.vue';
import VCollaboratorsList from './VCollaboratorsList.vue';
import VSelect from './VSelect.vue';
import VTextarea from './VTextarea.vue';
import VContentEditable from './VContentEditable.vue';

// Other
import ITask from '../core/Models/ITask';
import IUser from '@/core/Models/IUser';
import StoryType from '@/core/Values/StoryType';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import IAttachment from '@/core/Values/IAttachment';
import { fileExtension } from '@/utils/utils';
import Status from '@/core/Values/Status';
import IProject from '@/core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import IUploader from '@/core/Uploader/IUploader';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import scrollIntoView from 'scroll-into-view';
import { debounce } from 'debounce';
import { defineComponent, ref, nextTick } from 'vue';
import { PropType } from 'vue';
import UITaskMixin from '../mixins/UITaskMixin';
import { viewModeOptions } from '@/core/Values/StoryViewMode';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        DotsSvg,
        TimesSvg,
        NoticeSvg,

        VTaskStatus,
        VButton,
        VButtonDropdown,
        VColumn,
        VTaskCard,
        VFileList,
        VUsersList,
        VChatInput,
        VFeedStory,
        VDatePicker,
        VInlineLink,
        VUserSelector,
        VApprovementList,
        VCollaboratorsList,
        VSelect,
        VTextarea,
        VContentEditable,
    },

    setup(props) {
        const formErrors = ref(null as Record<string, string> | null);
        const formErrorsDebounce = ref(null as DebounceFunction | null);

        const showApproversBlock = ref(false);
        const showApproversBlockApprovers = ref([] as IUser[]);

        const approversBlock = ref(null as HTMLElement | null);

        const mixin = UITaskMixin(
            ref(props.goal),
            (callback?: () => void): void => {
                formErrors.value = mixin.validateGoalFields(mixin.taskOrGoal.value);

                if (!formErrors.value) {
                    callback?.();
                    formErrorsDebounce.value?.flush();
                    formErrorsDebounce.value = null;
                    return;
                }

                if (!formErrorsDebounce.value) {
                    formErrorsDebounce.value = debounce(
                        () => (formErrors.value = formErrorsDebounce.value = null),
                        3000,
                    ); // 3s
                }

                formErrorsDebounce.value();
            },
            (): void => {
                showApproversBlock.value = true;
                showApproversBlockApprovers.value = mixin.taskOrGoal.value.approvements?.length
                    ? mixin.taskOrGoal.value.approvements
                          ?.filter((approvement) => approvement.approver)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.approver!) ?? []
                    : mixin.taskOrGoal.value.collaborators
                          ?.filter(
                              (collaborator) => collaborator.user && collaborator.role === CollaboratorRole.Assignee,
                          )
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.user!) ?? [];

                nextTick(() => {
                    if (!approversBlock.value) {
                        return;
                    }

                    scrollIntoView(approversBlock.value, {
                        time: 250,
                    });
                });
            },
        );

        return {
            ...mixin,
            formErrors,
            formErrorsDebounce,
            showApproversBlock,
            showApproversBlockApprovers,
        };
    },

    props: {
        goal: { type: Object as PropType<ITask>, required: true },
        tasks: { type: Array as PropType<ITask[]>, required: true },
        uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
        project: { type: Object as PropType<IProject | null>, default: null },
        board: { type: Object as PropType<IBoard | null>, default: null },
        showStories: { type: Boolean, default: true },
    },

    data: () => ({
        viewModeOptions,

        goalSelectOptions: [] as ITask[],

        showAuthorBlock: false,
        showAuthorBlockAuthors: [] as IUser[],

        roles: CollaboratorRole,
        collaboratorsBlockRole: CollaboratorRole.Assignee as CollaboratorRole,
    }),

    computed: {
        isDraft(): boolean {
            return this.goal.status === Status.Draft;
        },

        isCompleted(): boolean {
            return this.goal.status === Status.Completed;
        },

        isInProgress(): boolean {
            return this.goal.status === Status.InProgress;
        },

        managers(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        contributors(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        collaborators(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        attachments(): IAttachment[] {
            if (!this.goal.stories) {
                return [];
            }

            return this.goal.stories
                .filter((story) => story.type === StoryType.Attachment)
                .map(
                    (attachment): IAttachment => ({
                        id: attachment.id,
                        authorId: attachment.actorId,
                        fileName: attachment.fileName,
                        objectName: attachment.objectName,
                        extension: fileExtension(attachment.fileName),
                        downloadUri: attachment.downloadUri,
                    }),
                );
        },
    },

    methods: {
        showMangers(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        showCollaborators(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        showCollaboratorsWithRole(role: CollaboratorRole): void {
            this.collaboratorsBlockRole = role;
        },

        startApprovementProcess(approvers: IUser[]): void {
            this.showApproversBlock = false;
            this.startApprovementProcessAsync(
                this.goal,
                approvers.map((approver) => approver.id),
            );
        },

        hideAuthorSelector(): void {
            this.showAuthorBlock = false;
            this.showAuthorBlockAuthors = [];
        },

        showAuthorSelector(): void {
            if (!this.goal.author) {
                return;
            }

            this.showAuthorBlock = true;
            this.showAuthorBlockAuthors = [this.goal.author];

            this.$nextTick(() => {
                if (!this.$refs.authorBlock) {
                    return;
                }

                scrollIntoView(this.$refs.authorBlock as HTMLElement, {
                    time: 250,
                });
            });
        },

        hideApproverSelector(): void {
            this.showApproversBlock = false;
            this.showApproversBlockApprovers = [];
        },

        getGoalId(goal: ITask): number {
            return goal.id;
        },

        getOptionValue(option: { value: number }): number {
            return option.value;
        },

        getCollaboratorsWithRole(role: CollaboratorRole): IUser[] {
            return (
                this.goal.collaborators?.reduce((result, collaborator) => {
                    if (collaborator.user && collaborator.role === role) {
                        result.push(collaborator.user);
                    }

                    return result;
                }, [] as IUser[]) ?? []
            );
        },

        setAuthor(authors: IUser[]): void {
            if (authors.length <= 0) {
                return;
            }

            this.showAuthorBlock = false;
            this.changeAuthorAsync(this.goal, authors[0].id);
        },

        applyRouteAction(): void {
            const action = this.$route.query.action;

            switch (action) {
                case 'validate':
                    this.validate();
                    break;
                case 'select-approvers':
                    this.showApproverSelector();
                    break;
            }

            if (action) {
                this.$router.replace({ query: { ...this.$route.query, action: undefined } });
            }
        },
    },

    watch: {
        '$route.query.action': {
            handler: 'applyRouteAction',
        },
    },

    mounted() {
        this.applyRouteAction();
    },
});
