
// Components
import VTaskCard from '../components/VTaskCard.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';

// Other
import sortBy from 'lodash.sortby';
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw, ref } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import Constants from '@/core/Constants';
import Status from '@/core/Values/Status';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import { defineComponent } from 'vue';
import orderBy from 'lodash.orderby';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';

export default defineComponent({
    components: {
        VTaskCard,
        VTaskFilter,
        VSearchField,
        VPage,
        VPageHeader,
        VPageContent,
        VBoard,
        VColumn,
    },

    setup() {
        const { t } = useI18n();
        return {
            t,

            title: ref(''),
            tasks: ref([] as ITask[]),
            searchString: ref(''),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.tasks', { value: [], formattedValue: {} }),

        currentUser(): IUser {
            return store.state.user;
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-draft-column-title'),
                    boardId: Constants.DraftId,
                    projectId: -1,
                },
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-approvements-column-title'),
                    boardId: Constants.ApprovementId,
                    projectId: -1,
                },
            ];

            if (!store.state.projects?.length || !store.state.boards?.length) {
                return columns;
            }

            columns = store.state.projects.reduce((carry, project): IColumn[] => {
                const boards = store.state.boards?.filter(
                    (board) => board.type === ProjectBoardType.Tasks && board.projectId === project.id,
                );

                if (!boards?.length) {
                    return carry;
                }

                const projectTitle = tryTranslate(project.title);
                for (const board of boards) {
                    const boardTitle = tryTranslate(board.title);
                    const title = boardTitle + ', ' + projectTitle;
                    const orderStr = projectTitle + boardTitle;
                    const column: IColumn = {
                        id: -1,
                        order: 1,
                        orderStr: orderStr,
                        title: title,
                        boardId: board.id,
                        projectId: board.projectId,
                    };

                    carry.push(column);
                }

                return carry;
            }, columns);

            return sortBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        groupedTasks(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                carry[column.boardId] = orderBy(this.getTasks(column), 'id', 'desc');

                return carry;
            }, {} as Record<number, ITask[]>);
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.filters.formattedValue,
                whereType: [TaskType.Task, TaskType.Subtask],
                whereColumnId: this.columns?.map((column) => column.id).filter((id) => id > 0),
                whereCollaborator: [this.currentUser?.id as string],
                includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators', 'preview'],
                search: this.searchString,
            };
        },
    },
    methods: {
        getTasks(column: IColumn): ITask[] {
            if (!this.tasks?.length) {
                return [];
            }

            if (column.boardId === Constants.DraftId) {
                return this.tasks.filter((task) => task.status === Status.Draft);
            }

            if (column.boardId === Constants.ApprovementId) {
                return this.tasks.filter((task) => task.status === Status.Approvement);
            }

            const boardId = column.boardId > 0 ? column.boardId : null;

            return this.tasks.filter((task) => task.boardId === boardId) ?? [];
        },

        async fetchData(): Promise<void> {
            this.tasks = await TaskService.queryAsync(this.tasksFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasks);
            ObjectStorageMapper.mapTasksAsync(this.tasks);

            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.tasks, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.tasksFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);
        },

        onTaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }): void {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },
    },

    created(): void {
        this.title = this.t('my-tasks');

        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted(): void {
        setPageTitle(this.t('my-tasks'));
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
