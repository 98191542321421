
// Components
import VTaskCard from '../components/VTaskCard.vue';
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '@/components/VBoard.vue';
import VColumn from '../components/VColumn.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import scrollIntoView from 'scroll-into-view';
import UserMapper from '@/core/UserMapper';
import Status from '@/core/Values/Status';
import TaskType from '@/core/Values/TaskType';
import { Raw, markRaw, ref } from 'vue';
import { TaskMutatorContext, TaskMutator } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { defineComponent } from 'vue';
import IApprovement from '@/core/Models/IApprovement';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import { useI18n } from 'vue-i18n';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';

class ApprovementTaskMutator extends TaskMutator {
    protected async createApprovement(
        context: TaskMutatorContext,
        approvement: IApprovement,
    ): Promise<ITask | undefined> {
        const updatedTask = await super.createApprovement(context, approvement);

        if (approvement.approverId === store.state.user?.id && !updatedTask) {
            const tasks = await TaskService.queryAsync({
                whereId: [approvement.taskId as number],
                whereStatus: [Status.Approvement],
                whereApprovementRequired: true,
                includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
                perPage: 1,
            });

            if (tasks.length) {
                context.tasks.push(tasks[0]);
                return tasks[0];
            }
        }

        return updatedTask;
    }
}

const approvementTaskMutator = markRaw(new ApprovementTaskMutator());

export default defineComponent({
    components: {
        VTaskCard,
        VGoalCard,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VBoard,
        VColumn,
    },

    setup() {
        const { t } = useI18n();

        return {
            t,

            searchString: ref(''),
            tasksAndGoals: ref([] as ITask[]),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.approvements', {
            value: [],
            formattedValue: {},
        }),

        filterKey(): string {
            return Settings.UI.Filters + '.' + (this.$route.name?.toString() ?? 'shared');
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        tasks(): ITask[] {
            return this.tasksAndGoals.filter((task) => task.type !== TaskType.Goal);
        },

        goals(): ITask[] {
            return this.tasksAndGoals.filter((task) => task.type === TaskType.Goal);
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.filters.formattedValue,
                whereStatus: [Status.Approvement],
                whereApprovementRequired: true,
                includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
                search: this.searchString,
            };
        },
    },

    methods: {
        TaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }) {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },

        async fetchData(): Promise<void> {
            this.tasksAndGoals = await TaskService.queryAsync(this.tasksFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasksAndGoals);
            ObjectStorageMapper.mapTasksAsync(this.tasksAndGoals);

            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(
                new TaskMutatorContext(
                    this.tasksAndGoals,
                    {
                        mapUsers: true,
                        mapPreview: true,
                        fetchTask: async (patch: Partial<ITask>) => {
                            if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                                return undefined;
                            }

                            const tasks = await TaskService.queryAsync({
                                ...this.tasksFilters,
                                whereId: [patch.id as number],
                                perPage: 1,
                            });

                            if (!tasks.length) {
                                return undefined;
                            }

                            return UserMapper.mapTaskAsync(tasks[0]);
                        },

                        // Excludes tasks if they are not matching the specified filters.
                        excludeTask: (task: ITask) =>
                            !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                        ignoreTaskCreating: () => true,
                    },
                    approvementTaskMutator, // Custom mutator
                ),
            );
            MutationBus.activate(this.mutatorContext);
        },
    },

    created() {
        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted() {
        setPageTitle(this.t('approvements'));
    },

    beforeUnmount(): void {
        MutationBus.deactivate(this.mutatorContext);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.TaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
