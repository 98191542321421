import { MutationTree } from 'vuex';
import { IState } from '.';
import IProject from '@/core/Models/IProject';
import IUser from '@/core/Models/IUser';
import ApplicationState from '@/core/Values/ApplicationState';
import IBoard from '@/core/Models/IBoard';
import IMutationPatch from '@/core/Mutations/IMutationPatch';
import diffPatcher from '@/core/Mutations/Patcher';

export class MutationNames {
    public static readonly Flush = 'FLUSH';

    public static readonly LoadApplicationError = 'LOAD_APPLICATION_ERROR';
    public static readonly StartApplicationLoading = 'START_APPLICATION_LOADING';
    public static readonly FinishApplicationLoading = 'FINISH_APPLICATION_LOADING';

    public static readonly SetUser = 'SET_USER';
    public static readonly SetUserPermissions = 'SET_USER_PERMISSIONS';

    public static readonly SetUsers = 'SET_USERS';
    public static readonly SetProjects = 'SET_PROJECTS';
    public static readonly SetBoards = 'SET_BOARDS';

    public static readonly CreateProject = 'CREATE_PROJECT';
    public static readonly UpdateProject = 'UPDATE_PROJECT';
    public static readonly DeleteProject = 'DELETE_PROJECT';
    public static readonly PatchProject = 'PATCH_PROJECT';

    public static readonly CreateBoard = 'CREATE_BOARD';
    public static readonly UpdateBoard = 'UPDATE_BOARD';
    public static readonly DeleteBoard = 'DELETE_BOARD';
    public static readonly PatchBoard = 'PATCH_BOARD';

    public static readonly StorageProxySet = 'SET_STORAGE_PROXY';
}

const mutations: MutationTree<IState> = {
    [MutationNames.Flush]: (state: IState) => {
        state.appError = null;
        state.appState = ApplicationState.Loading;
        state.user = { id: '', displayName: '' };
        state.userSettings = {
            locale: 'ru',
        };
        state.userPermissions = [];
        state.usersMap = new Map<string, IUser>();
        state.objectStorageMap = new Map<string, string>();
        state.boards = null;
        state.projects = null;
        state.storageProxy = new Map<string, unknown>();
    },

    [MutationNames.LoadApplicationError]: (state: IState, error) => {
        state.appError = error;
        state.appState = ApplicationState.Error;
    },
    [MutationNames.StartApplicationLoading]: (state: IState) => {
        state.appState = ApplicationState.Loading;
    },
    [MutationNames.FinishApplicationLoading]: (state: IState) => {
        state.appState = ApplicationState.Loaded;
    },

    [MutationNames.SetUser]: (state: IState, user: IUser) => {
        state.user = user;
    },
    [MutationNames.SetUserPermissions]: (state: IState, permissions?: string[]) => {
        state.userPermissions = permissions ?? [];
    },

    [MutationNames.SetProjects]: (state: IState, projects?: IProject[]) => {
        state.projects = projects ?? [];
    },
    [MutationNames.SetBoards]: (state: IState, boards?: IBoard[]) => {
        state.boards = boards ?? [];
    },
    [MutationNames.SetUsers]: (state: IState, users?: IUser[]) => {
        if (!users) {
            return;
        }

        state.usersMap = users.reduce((map, user) => map.set(user.id, user), new Map<string, IUser>());
    },

    [MutationNames.CreateProject]: (state: IState, project: IProject) => {
        state.projects?.push(project);
    },

    [MutationNames.UpdateProject]: (state: IState, project: IProject) => {
        if (!state.projects) {
            state.projects = [];
        }

        const carry = state.projects.find((item) => item.id === project.id);

        if (!carry) {
            return;
        }

        Object.assign(carry, project);
    },

    [MutationNames.DeleteProject]: (state: IState, project: IProject) => {
        if (!state.projects) {
            return;
        }

        const index = state.projects.findIndex((item) => item.id === project.id);

        if (index !== -1) {
            state.projects.splice(index, 1);
        }
    },

    [MutationNames.PatchProject]: (state: IState, patch: IMutationPatch) => {
        if (!patch.id) {
            return;
        }

        if (!state.projects) {
            state.projects = [];
        }

        const project = state.projects.find((item) => item.id === patch.id);

        if (!project) {
            return;
        }

        diffPatcher.patch(project, patch.patch);
    },

    [MutationNames.CreateBoard]: (state: IState, board: IBoard) => {
        state.boards?.push(board);
    },

    [MutationNames.UpdateBoard]: (state: IState, board: IBoard) => {
        if (!state.boards) {
            state.boards = [];
        }

        const carry = state.boards.find((item) => item.id === board.id);

        if (!carry) {
            return;
        }

        Object.assign(carry, board);
    },

    [MutationNames.DeleteBoard]: (state: IState, board: IBoard) => {
        if (!state.boards) {
            return;
        }

        const index = state.boards.findIndex((item) => item.id === board.id);

        if (index !== -1) {
            state.boards.splice(index, 1);
        }
    },

    [MutationNames.PatchBoard]: (state: IState, patch: IMutationPatch) => {
        if (!patch.id) {
            return;
        }

        if (!state.boards) {
            state.boards = [];
        }

        const project = state.boards.find((item) => item.id === patch.id);

        if (!project) {
            return;
        }

        diffPatcher.patch(project, patch.patch);
    },

    [MutationNames.StorageProxySet]: (state: IState, { key, value }: { key: string; value: unknown }) => {
        state.storageProxy.set(key, value);
    },
};

export default mutations;
