
// Svg
import BellSvg from '@/assets/bell.svg';
import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import ITask from '@/core/Models/ITask';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VLoader from '../components/VLoader.vue';
import VButton from '../components/VButton.vue';
import VGoalPanel from '../components/VGoalPanel.vue';
import VIllustration from '../components/VIllustration.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import TaskService from '@/core/Services/TaskService';
import VPageContent from '@/components/VPageContent.vue';
import { $error } from '@/utils/app-utils';
import { Raw, markRaw, ref } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { objectStorageUploader } from '@/core/Uploader/UploaderBuilder';
import MutationType from '@/core/Mutations/MutationType';
import ObjectType from '@/core/Mutations/ObjectType';
import scrollIntoView from 'scroll-into-view';
import { EventNames } from '@/core/EventNames';
import emitter from '@/core/Emitter';
import { defineComponent } from 'vue';
import APITaskMixin from '../mixins/APITaskMixin';
import IBoard from '@/core/Models/IBoard';
import store from '@/store';
import IProject from '@/core/Models/IProject';
import IUser from '@/core/Models/IUser';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        BellSvg,
        NoDataSvg,
        NoDataDarkSvg,

        VPage,
        VPageHeader,
        VPageContent,
        VLoader,
        VButton,
        VGoalPanel,
        VIllustration,
    },

    setup() {
        const { t } = useI18n();
        return {
            t,

            loading: ref(true),

            goal: ref(null as ITask | null),
            tasks: ref([] as ITask[]),
            pageTitle: ref(''),
            showStories: ref(true),

            uploader: objectStorageUploader,

            mutatorContexts: ref([] as Raw<Array<IMutatorContext>>),

            ...APITaskMixin,
        };
    },

    computed: {
        theme() {
            return Storages.Settings.get(Settings.UI.Theme);
        },

        goalId(): number {
            return parseInt(this.$route.params.goalId as string, 10);
        },

        board(): IBoard | undefined {
            return store.state.boards?.find((board) => board.id === this.goal?.boardId);
        },

        project(): IProject | undefined {
            return store.state.projects?.find((project) => project.id === this.goal?.projectId);
        },

        currentUser(): IUser | null {
            return store.state.user;
        },
    },

    methods: {
        async createEmptyTask() {
            if (!this.goal) {
                return;
            }

            try {
                var mutations = await TaskService.createAsync(TaskType.Task, this.goal.id);

                const mutation = mutations.find(
                    (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
                );

                if (!mutation) {
                    return;
                }

                this.$router.replace({ query: { task: (mutation.objectState as ITask).id } });
            } catch (error) {
                $error(error);
            }
        },

        onTaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }) {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },

        async fetchData() {
            if (!this.goalId) {
                return;
            }

            this.loading = true;

            try {
                const [goals, tasks] = await Promise.all([
                    TaskService.queryAsync({
                        whereId: [this.goalId],
                        whereType: [TaskType.Goal],
                        withArchived: true,
                        includes: ['stories', 'collaborators', 'approvements', 'save-points-count'],
                        perPage: 1,
                    }),
                    TaskService.queryAsync({
                        whereTaskId: [this.goalId],
                        whereType: [TaskType.Task],
                        includes: ['comments-count', 'attachments-count', 'collaborators'],
                    }),
                ]);

                const goal = goals.length > 0 ? goals[0] : null;

                if (!goal) {
                    return;
                }

                const tasksForMapping = [goal, ...tasks];
                UserMapper.mapTasksAsync(tasksForMapping);
                ObjectStorageMapper.mapTasksAsync(tasksForMapping);

                this.pageTitle = this.t('goal-number', { number: goal.id });
                this.goal = goal;
                this.tasks = tasks;

                // Creates the mutation context for watching for goal.
                const goalContext = new TaskMutatorContext([this.goal], {
                    mapUsers: true,
                    mapAttachments: true,
                    ignoreTaskCreating: () => true,
                });
                const tasksContext = new TaskMutatorContext(this.tasks, {
                    mapUsers: false,
                    mapPreview: true,
                    // Fetches a task if the task was attached to the current goal.
                    fetchTask: async (changes: unknown) => {
                        const task = changes as { id?: number; parentId?: number[] };

                        if (!task.id || task.parentId?.[1] !== this.goal?.id) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            whereId: [task.id as number],
                            includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
                            perPage: 1,
                        });

                        return tasks.length ? tasks[0] : undefined;
                    },
                    // Excludes the task if the task was not attached to the current goal.
                    excludeTask: (task: ITask) => {
                        return task.parentId !== this.goal?.id;
                    },
                    ignoreTaskCreating: (task: ITask) => task.parentId !== this.goal?.id,
                });

                // Deactivate old context, to avoid memory leaks.
                MutationBus.deactivate(this.mutatorContexts);
                this.mutatorContexts = markRaw([goalContext, tasksContext]);
                MutationBus.activate(this.mutatorContexts);

                setPageTitle(this.pageTitle);
            } catch (error) {
                $error(error);
            } finally {
                this.loading = false;
            }
        },
    },

    watch: {
        '$route.params.goalId': {
            handler: 'fetchData',
        },
    },

    created(): void {
        this.fetchData();

        this.showStories = this.$device.isDesktop;

        emitter.on(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.v
        MutationBus.deactivate(this.mutatorContexts);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
