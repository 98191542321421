
// Components
import VAppError from './components/VAppError.vue';
import VAppLayout from './components/VAppLayout.vue';
import VModalError from './components/VModalError.vue';
import VModalAlert from './components/VModalAlert.vue';
import VModalConfirm from './components/VModalConfirm.vue';
import VModalImageViewer from './components/VModalImageViewer.vue';
import VLoader from './components/VLoader.vue';
import VLoaderPacman from './components/VLoaderPacman.vue';
import { Splitpanes as VSplitpanes, Pane as VPane } from 'splitpanes';

// Other
import store from './store';
import { ActionNames } from './store/actions';
import ApplicationState from './core/Values/ApplicationState';
import connection from './core/WebSocket';
import { EventNames } from './core/EventNames';
import Storages from './core/Storages';
import Settings from './core/Settings';
import { $error } from './utils/app-utils';
import { MutationNames } from './store/mutations';
import { defineComponent } from 'vue';
import bus from './core/Mutations/MutationBus';

export default defineComponent({
    components: {
        VPane,
        VSplitpanes,

        VModalError,
        VModalAlert,
        VModalConfirm,
        VModalImageViewer,
        VAppError,
        VAppLayout,
        // eslint-disable-next-line vue/no-unused-components
        VLoader, // DON'T REMOVE, style loading
        // eslint-disable-next-line vue/no-unused-components
        VLoaderPacman, // DON'T REMOVE, style loading
    },

    methods: {
        async startApp() {
            const timeout = setTimeout(this.startPacMan, 5000); // 5s

            try {
                await Promise.all([store.dispatch(ActionNames.StartApplicationLoadingAsync), connection.start()]);
                await store.dispatch(ActionNames.FinishApplicationLoadingAsync);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const errorMessage: string = error?.message ?? '';
                if (errorMessage.startsWith('application.')) {
                    store.commit(MutationNames.LoadApplicationError, error);
                    return;
                }

                $error(error);
            } finally {
                clearTimeout(timeout);
                this.removeAppLoader();
            }

            const listenEventNames = [
                EventNames.DbObjectsMutated,
                EventNames.NotificationRead,
                EventNames.NotificationCreated,
                EventNames.NotificationDeleted,
            ];

            listenEventNames.forEach((eventName) => {
                connection.on(eventName, (params) => {
                    bus.mutate(eventName, params);
                });
            });
        },

        startPacMan() {
            const pacman = document.getElementById('app-loader-pacman');
            const spinner = document.getElementById('app-loader-spinner');

            if (pacman && spinner) {
                pacman.style.display = 'block';
                spinner.style.display = 'none';
            }
        },

        removeAppLoader() {
            const loader = document.getElementById('app-loader');
            if (loader) {
                document.body.removeChild(loader);
            }
        },

        flushApp() {
            store.commit(MutationNames.Flush);
        },
    },

    computed: {
        panelWidth: Storages.Settings.computedDebounce(Settings.UI.PanelWidth, 50),

        state() {
            return store.state.appState;
        },

        error() {
            return store.state.appError as Record<string, unknown>;
        },

        isReady() {
            return store.state.appState === ApplicationState.Loaded;
        },

        isError() {
            return store.state.appState === ApplicationState.Error;
        },

        isLoading() {
            return store.state.appState === ApplicationState.Loading || true;
        },
    },

    async created() {
        await this.startApp();
    },
});
