
// Components
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VColumn from '../components/VColumn.vue';
import VBoard from '../components/VBoard.vue';

// Other
import sortBy from 'lodash.sortby';
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw, ref } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import MutationBus from '@/core/Mutations/MutationBus';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import Constants from '@/core/Constants';
import Status from '@/core/Values/Status';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import { defineComponent } from 'vue';
import orderBy from 'lodash.orderby';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';

export default defineComponent({
    components: {
        VGoalCard,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VColumn,
        VBoard,
    },

    setup() {
        const { t } = useI18n();

        return {
            t,

            goals: ref([] as ITask[]),
            searchString: ref(''),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.goals', {
            value: [],
            formattedValue: {},
        }),

        filterKey(): string {
            return Settings.UI.Filters + '.' + (this.$route.name?.toString() ?? 'shared');
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-draft-column-title'),
                    boardId: Constants.DraftId,
                    projectId: -1,
                },
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-approvements-column-title'),
                    boardId: Constants.ApprovementId,
                    projectId: -1,
                },
            ];

            if (!store.state.projects?.length || !store.state.boards?.length) {
                return columns;
            }

            columns = store.state.projects.reduce((carry, project): IColumn[] => {
                const boards = store.state.boards?.filter(
                    (board) => board.type === ProjectBoardType.Goals && board.projectId === project.id,
                );

                if (!boards?.length) {
                    return carry;
                }

                const projectTitle = tryTranslate(project.title);
                for (const board of boards) {
                    const boardTitle = tryTranslate(board.title);

                    const title = boardTitle + ', ' + projectTitle;
                    const orderStr = projectTitle + boardTitle;
                    const column: IColumn = {
                        id: -1,
                        order: 1,
                        title: title,
                        orderStr: orderStr,
                        boardId: board.id,
                        projectId: board.projectId,
                    };

                    carry.push(column);
                }

                return carry;
            }, columns);

            return sortBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        groupedGoals(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                carry[column.boardId] = orderBy(this.getTasks(column), 'id', 'desc');

                return carry;
            }, {});
        },

        goalsFilters(): QueryTaskRequest {
            return {
                ...this.filters.formattedValue,
                whereType: [TaskType.Goal],
                whereColumnId: this.columns?.map((column) => column.id).filter((id) => id > 0),
                whereCollaborator: [this.currentUser?.id as string],
                includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
                search: this.searchString,
            };
        },
    },

    methods: {
        getTasks(column: IColumn): ITask[] {
            if (!this.goals?.length) {
                return [];
            }

            if (column.boardId === Constants.DraftId) {
                return this.goals.filter((goal) => goal.status === Status.Draft);
            }

            if (column.boardId === Constants.ApprovementId) {
                return this.goals.filter((goal) => goal.status === Status.Approvement);
            }

            const boardId = column.boardId > 0 ? column.boardId : null;

            return this.goals.filter((goal) => goal.boardId === boardId) ?? [];
        },

        async fetchData(): Promise<void> {
            this.goals = await TaskService.queryAsync(this.goalsFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.goals);
            ObjectStorageMapper.mapTasksAsync(this.goals);

            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.goals, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.goalsFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.goalsFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.goalsFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.goalsFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);
        },
    },

    created(): void {
        this.fetchData();

        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted(): void {
        setPageTitle(this.t('my-goals'));
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
