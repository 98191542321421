
// Svg
import DotsSvg from '@/assets/dots.svg';
import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';
import RemoveSvg from '@/assets/remove.svg';
import MarkAsReadSvg from '@/assets/mark-as-read.svg';

// Components
import VSelect from '../components/VSelect.vue';
import VLoader from '../components/VLoader.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VIllustration from '../components/VIllustration.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';
import VNotificationCard from '../components/VNotificationCard.vue';

// Other
import IUser from '@/core/Models/IUser';
import store from '@/store';
import { setPageTitle } from '@/utils/document-utils';
import INotification from '@/core/Models/INotification';
import AggregatorService from '@/core/Services/AggregatorService';
import { $error } from '@/utils/app-utils';
import TaskType from '@/core/Values/TaskType';
import NotificationService, { QueryNotificationRequest } from '@/core/Services/NotificationService';
import { Raw, markRaw, ref } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { NotificationMutatorContext } from '@/core/Mutators/NotificationMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import orderBy from 'lodash.orderby';
import { defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import { useI18n } from 'vue-i18n';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';

enum FilterType {
    All,
    Read,
    Unread,
}

type FilterOption = { title: string; value: FilterType };

export default defineComponent({
    components: {
        DotsSvg,
        NoDataSvg,
        NoDataDarkSvg,

        VSelect,
        VLoader,
        VPage,
        VPageHeader,
        VPageContent,
        VIllustration,
        VButtonDropdown,
        VNotificationCard,
    },

    setup() {
        const { t } = useI18n();

        const options = [
            {
                title: t('all'),
                value: FilterType.All,
            },
            {
                title: t('read'),
                value: FilterType.Read,
            },
            {
                title: t('unread'),
                value: FilterType.Unread,
            },
        ];

        const markAllAsReadAsync = async (): Promise<void> => {
            try {
                await NotificationService.markAsReadAsync({
                    whereUnread: true,
                });
            } catch (error) {
                $error(error);
            }
        };

        const removeAllNotification = async (): Promise<void> => {
            try {
                await NotificationService.removeAsync({});
            } catch (error) {
                $error(error);
            }
        };

        const removeAllReadNotification = async (): Promise<void> => {
            try {
                await NotificationService.removeAsync({
                    whereRead: true,
                });
            } catch (error) {
                $error(error);
            }
        };

        return {
            t,

            markAllAsReadAsync,
            removeAllNotification,
            removeAllReadNotification,

            pageTitle: ref(''),
            isLoading: ref(true),
            hasNextPage: ref(true),

            notifications: ref([] as INotification[]),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            filter: ref(options[0] as FilterOption),
            filterOptions: options,
            options: ref([
                {
                    icon: markRaw(MarkAsReadSvg),
                    title: t('mark-all-as-read'),
                    action: markAllAsReadAsync,
                },
                {
                    icon: markRaw(RemoveSvg),
                    title: t('remove-all-notifications'),
                    action: removeAllNotification,
                },
                {
                    icon: markRaw(RemoveSvg),
                    title: t('remove-all-read-notifications'),
                    action: removeAllReadNotification,
                },
            ] as IDropdownOption[]),
        };
    },

    computed: {
        theme() {
            return Storages.Settings.get(Settings.UI.Theme);
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        sortedNotifications(): INotification[] {
            return orderBy(this.notifications, (notification) => notification.id, 'desc');
        },
    },

    methods: {
        getGotoLink(notification: INotification): RouteLocationRaw {
            const taskId = notification.payload?.taskId;
            const isGoal = notification.payload?.task?.type === TaskType.Goal;

            if (isGoal) {
                return { name: 'goals.view', params: { goalId: taskId } };
            }

            return { query: { task: taskId, selected: notification.id } };
        },

        fetchMoreNotifications(): void {
            if (!this.hasNextPage || this.isLoading) {
                return;
            }

            this.fetchNotificationsAsync();
        },

        async markAsReadAsync(notification: INotification): Promise<void> {
            if (notification.readAt) {
                return;
            }

            try {
                await NotificationService.markAsReadAsync(notification.id);
            } catch (error) {
                $error(error);
            }
        },

        async removeNotification(notification: INotification): Promise<void> {
            try {
                await NotificationService.removeAsync(notification.id);
            } catch (error) {
                $error(error);
            }
        },
        async fetchNotificationsAsync(): Promise<void> {
            this.isLoading = true;

            try {
                const request: QueryNotificationRequest = {
                    perPage: 10,
                };

                if (this.filter.value === FilterType.Read) {
                    request.whereRead = true;
                }

                if (this.filter.value === FilterType.Unread) {
                    request.whereUnread = true;
                }

                if (this.notifications.length > 0) {
                    // Adds the condition to exclude loaded notifications.
                    var lastNotification = orderBy(this.notifications, (notification) => notification.id, 'asc')[0];
                    request.whereIdLess = lastNotification.id;
                }

                const response = await AggregatorService.queryNotificationsApiAsync(request);

                this.hasNextPage = !!response.metadata?.nextPageUri;

                for (const notification of response.data) {
                    this.notifications.push(notification);
                }
            } catch (error) {
                $error(error);
                this.hasNextPage = false;
            } finally {
                this.isLoading = false;
            }
        },

        async fetchData(): Promise<void> {
            await this.fetchNotificationsAsync();
            MutationBus.deactivate(this.mutatorContext);
            this.mutatorContext = markRaw(new NotificationMutatorContext(this.notifications));
            MutationBus.activate(this.mutatorContext);
        },

        onFilterChanged(): void {
            // Clear current notifications
            this.notifications.splice(0, this.notifications.length);
            this.fetchNotificationsAsync();
        },
    },

    watch: {
        filter: {
            handler: 'onFilterChanged',
        },
    },

    created() {
        this.fetchData();

        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted() {
        this.pageTitle = this.t('notifications');
        setPageTitle(this.pageTitle);
    },

    beforeUnmount(): void {
        MutationBus.deactivate(this.mutatorContext);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
