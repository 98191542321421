import { defineComponent } from 'vue';
import store from '@/store';
import IBoard from '@/core/Models/IBoard';
import IProject from '@/core/Models/IProject';
import { markRaw } from 'vue';
import ITask from '@/core/Models/ITask';
import { LexoRank } from 'lexorank';
import IUser from '@/core/Models/IUser';
import ColumnService from '@/core/Services/ColumnService';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import IColumn from '@/core/Models/IColumn';
import { $error } from '@/utils/app-utils';
import TaskService, { QueryTaskRequest } from '@/core/Services/TaskService';
import { Raw } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import orderBy from 'lodash.orderby';
import TaskType from '@/core/Values/TaskType';
import UserMapper from '@/core/UserMapper';
import BoardService from '@/core/Services/BoardService';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import { ColumnMutatorContext } from '@/core/Mutators/ColumnMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import { setPageTitle } from '@/utils/document-utils';
import scrollIntoView from 'scroll-into-view';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import { PropType } from 'vue';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';

export default defineComponent({
    props: {
        board: { type: Object as PropType<IBoard>, required: true },
        project: { type: Object as PropType<IProject>, required: true },
    },

    data() {
        return {
            pageTitle: '',
            searchString: '',
            tasks: [] as ITask[],
            columns: [] as IColumn[],
            mutatorContexts: [] as Raw<Array<IMutatorContext>>,
            loadOnly: [] as TaskType[],
        };
    },

    computed: {
        filters: Storages.Filters.computed(Settings.UI.Filters + '.shared', { value: [], formattedValue: {} }),

        currentUser(): IUser | null {
            return store.state.user;
        },

        groupedTasks(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                const goals = this.tasks.filter((goal) => goal.columnId === column.id) ?? [];

                carry[column.id] = orderBy(goals, 'rank');

                return carry;
            }, {} as Record<number, ITask[]>);
        },

        canCreateColumn(): boolean {
            return AuthorizationProvider.authorize(null, Operations.CreateColumn);
        },

        tasksFilters(): QueryTaskRequest {
            return {
                ...this.filters.formattedValue,
                whereType: this.loadOnly,
                whereBoardId: [this.board.id],
                includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
                search: this.searchString,
            };
        },
    },

    methods: {
        onMove(column: IColumn, event: { newIndex: number }) {
            const tasks = this.groupedTasks[column.id];
            if (!tasks) {
                return;
            }

            const task = tasks[event.newIndex];

            if (!task) {
                return;
            }

            const rank = this.getNewTaskRank(tasks, task, event.newIndex);

            // To avoid jumping between columns.
            task.rank = rank;
            task.columnId = column.id;

            this.moveToColumn(task, column, rank);
        },

        canUpdateColumnTitle(column: IColumn): boolean {
            return AuthorizationProvider.authorize(column, Operations.UpdateColumn);
        },

        async createColumn() {
            if (!this.board.id) {
                return;
            }

            try {
                await ColumnService.createAsync(this.board.id);
            } catch (error) {
                $error(error);
            }
        },

        async setColumnTitle(column: IColumn, newTitle: string) {
            if (!column) {
                return;
            }

            try {
                await ColumnService.setTitleAsync(column.id, newTitle);
            } catch (error) {
                $error(error);
            }
        },

        async moveToColumn(goal: ITask, column: IColumn, rank: string) {
            if (!goal || !column) {
                return;
            }

            try {
                await TaskService.moveAsync(goal.id, column.id, rank);
            } catch (error) {
                $error(error);
            }
        },

        getNewTaskRank(tasks: ITask[], task: ITask, newIndex: number): string {
            // Insert into empty column.
            if (tasks.length === 1) {
                return LexoRank.middle().toString();
            }

            // Insert between.
            if (newIndex > 0 && tasks.length > newIndex + 1) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const prevRank = tasks[newIndex - 1].rank!;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const nextRank = tasks[newIndex + 1].rank!;

                return LexoRank.parse(prevRank).between(LexoRank.parse(nextRank)).toString();
            }

            // Insert before.
            if (newIndex === 0 && tasks.length > 1) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const nextRank = tasks[newIndex + 1].rank!;

                return LexoRank.parse(nextRank).genPrev().toString();
            }

            // Insert last.
            if (newIndex + 1 === tasks.length && tasks.length > 1) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const prevRank = tasks[newIndex - 1].rank!;

                return LexoRank.parse(prevRank).genNext().toString();
            }

            return LexoRank.middle().toString();
        },

        async fetchData(): Promise<void> {
            if (!this.board || !this.project) {
                return;
            }

            this.tasks = await TaskService.queryAsync(this.tasksFilters);
            this.columns = await BoardService.getBoardColumnsAsync(this.board.id);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.tasks);
            ObjectStorageMapper.mapTasksAsync(this.tasks);

            // Creates the mutation context for watching for tasks.
            const tasksContext = new TaskMutatorContext(this.tasks, {
                mapUsers: true,
                mapPreview: true,
                // Fetches a task if the patch contains the changes that can affect of the filters.
                fetchTask: async (patch: Partial<ITask>) => {
                    if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.tasksFilters)) {
                        return undefined;
                    }

                    const tasks = await TaskService.queryAsync({
                        ...this.tasksFilters,
                        whereId: [patch.id as number],
                        perPage: 1,
                    });

                    if (!tasks.length) {
                        return undefined;
                    }

                    return UserMapper.mapTaskAsync(tasks[0]);
                },

                // Excludes tasks if they are not matching the specified filters.
                excludeTask: (task: ITask) =>
                    !TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
                // Ignores tasks creation if they are not matching the specified filters.
                ignoreTaskCreating: (task: ITask) =>
                    TaskService.match(task, this.currentUser?.id as string, this.tasksFilters),
            });
            const columnsContext = new ColumnMutatorContext(this.columns);

            // Deactivate old context, to avoid memory leaks.
            MutationBus.deactivate(this.mutatorContexts);
            this.mutatorContexts = markRaw([tasksContext, columnsContext]);
            MutationBus.activate(this.mutatorContexts);

            this.pageTitle = this.$tryTranslate(this.board.title) + ', ' + this.$tryTranslate(this.project.title);

            setPageTitle(this.pageTitle);
        },

        onTaskPanelBeforeOpen(event: { taskId: number; clientWidth: number }) {
            this.$nextTick(() => {
                const taskElement = document.querySelector(`.task-card[task-id='${event.taskId}']`) as HTMLElement;
                if (taskElement) {
                    scrollIntoView(taskElement, {
                        time: 250,
                    });
                }
            });
        },
    },

    watch: {
        '$route.params.boardId'() {
            this.fetchData();
        },
    },

    created() {
        this.fetchData();

        emitter.on(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
    beforeUnmount(): void {
        MutationBus.deactivate(this.mutatorContexts);

        emitter.off(EventNames.TaskPanelBeforeOpen, this.onTaskPanelBeforeOpen);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
